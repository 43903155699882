import { Grid } from "@material-ui/core";
import { differenceInDays } from "date-fns";
import { navigate } from "gatsby";
import { useContext, useMemo } from "react";
import { openPopupWidget } from "react-calendly";
import CallToActionButton from "../../components/CallToActionButton/call-to-action-button";
import HeaderComponent from "../../components/HeaderComponent/header-component";
import WistiaRatioMaintainedContainer from "../../components/wistia-ratio-maintained-container";
import WistiaVideoPlayer from "../../components/wistia-video-player";
import { FinanceContext } from "../../context/finance-context";
import { useMyBrokerInfo } from "../../hooks/broker.hooks";
import { useIsMobile, useStartLiveChat } from "../../hooks/common.hooks";
import { useLoanSubmission } from "../../hooks/loanApplication.hooks";
import { useScenarioList } from "../../hooks/scenario.hooks";
import { Clint } from "../../images";
import { Calendar, Call, StartLiveChat } from "../../svgs";
import BrokerNameCard from "./components/broker-name-card/broker-name-card";

const SmallSection = ({ title, content }) => {
  return (
    <div>
      <div className="help-small-section__title">{title}</div>
      <div className="help-small-section__content">{content}</div>
    </div>
  );
};

const HelpAndSupport = () => {
  const isAtMobileScreen = useIsMobile();
  const { setSelectedScenarioId } = useContext(FinanceContext);
  const { data: scenarioList = [] } = useScenarioList();
  const opportunityId = useMemo(() => {
    if (scenarioList.length) {
      return scenarioList[0].opportunityId;
    }
    return "";
  }, [scenarioList]);
  const daysDifference = useMemo(() => {
    if (scenarioList[0]?.dateSubmit) {
      return differenceInDays(new Date(), scenarioList[0]?.dateSubmit);
    }
    return 0;
  }, [scenarioList]);

  const [startLiveChat] = useStartLiveChat();
  const createLoanSubmissionMutation = useLoanSubmission(opportunityId);
  const handleBookCall = (url) => {
    openPopupWidget({ url });
  };

  const handleStartLoan = () => {
    if (scenarioList.length) {
      setSelectedScenarioId(scenarioList[0].id);
    }
    if (daysDifference > 30) {
      createLoanSubmissionMutation.mutate(scenarioList[0].id);
    }
    if (opportunityId) {
      navigate("finance/application-process");
    } else {
      navigate("finance/loan-application");
    }
  };
  const { data: brokerInfo } = useMyBrokerInfo();

  const isBrokerRole = brokerInfo?.staffRoles?.some(
    (role) => role.role === "BROKER"
  );

  const renderSmallSectionRow = (leftContent, rightContent) => {
    return (
      <Grid item container className="help-small-section-row">
        <Grid item xs={12} sm={6}>
          {leftContent}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          container
          justify="flex-end"
          alignItems="flex-start"
          className="help-small-section-row__right-content"
        >
          {rightContent}
        </Grid>
      </Grid>
    );
  };

  return (
    <div className="help-and-support-page">
      <HeaderComponent headerContent={<div>Help & Support</div>} />
      <Grid container className="help-and-support-page__main-container">
        <Grid item container alignItems="center" className="help-large-section">
          <Grid item xs={12} className="help-large-section__title">
            Your Advisors Details
          </Grid>
          <div className="help-large-section__broker-detail">
            <div className="help-large-section__name-card">
              <BrokerNameCard
                name={brokerInfo.name}
                title={isBrokerRole ? "Mortgage Broker" : "Finance Advisor"}
                phoneNumber={brokerInfo.phoneNumber}
                email={brokerInfo.email}
              />
              <div className="call-broker-btn-container">
                {Boolean(brokerInfo.calendlyUrl) && (
                  <CallToActionButton
                    title="Book Call Back"
                    icon={<Calendar />}
                    onClick={() => {
                      handleBookCall(brokerInfo.calendlyUrl);
                    }}
                  />
                )}
                <CallToActionButton
                  title="Start Loan Application"
                  iconDisabled
                  onClick={handleStartLoan}
                />
              </div>
            </div>

            {brokerInfo?.introductionVideoUrl ? (
              <div className="help-large-section__video">
                <WistiaRatioMaintainedContainer>
                  <WistiaVideoPlayer
                    key={brokerInfo.introductionVideoUrl}
                    embedId={brokerInfo.introductionVideoUrl}
                  />
                </WistiaRatioMaintainedContainer>
              </div>
            ) : (
              brokerInfo?.imageUrl && (
                <div className="help-large-section__image">
                  <img src={brokerInfo?.imageUrl} alt="broker avatar" />
                </div>
              )
            )}
          </div>
        </Grid>
        <Grid item container className="help-large-section">
          <Grid item md={12} container>
            <Grid item md={6} />
            <Grid
              item
              md={6}
              className="help-large-section__title help-large-section__title--left-padded"
            >
              In App Support, or Just Some Feedback. Let&apos;s&nbsp;Chat
            </Grid>
          </Grid>
          <Grid item md={12} container className="support-methods-container">
            <Grid
              item
              md={6}
              container
              alignItems="center"
              className="support-methods-container__video"
            >
              <div className="help-large-section__image">
                <img src={Clint} alt="broker avatar" />
              </div>
            </Grid>
            <Grid
              item
              md={6}
              container
              className="support-methods-container__list"
            >
              {renderSmallSectionRow(
                <SmallSection
                  title="Live chat with our tech support"
                  content="Talk to our specialist online to help answer any technical questions about how to use the site. "
                />,
                <CallToActionButton
                  title="Start Live Chat"
                  icon={<StartLiveChat />}
                  onClick={startLiveChat}
                />
              )}
              {!isAtMobileScreen
                ? renderSmallSectionRow(
                    <CallToActionButton
                      title={<a href="tel:0416338438">0416338438</a>}
                      icon={<Call />}
                    />,
                    <SmallSection
                      title="Call Clint our product lead"
                      content="Talk directly to Clint, our head of digital and product lead for WealthX."
                    />
                  )
                : renderSmallSectionRow(
                    <SmallSection
                      title="Call Clint our product lead"
                      content="Talk directly to Clint, our head of digital and product lead for WealthX."
                    />,
                    <CallToActionButton
                      title={<a href="tel:0416338438">0416338438</a>}
                      icon={<Call />}
                    />
                  )}
              {renderSmallSectionRow(
                <SmallSection
                  title="Book a call back with Clint"
                  content="If you don’t want to talk now, we can organise a time for Clint to call you back."
                />,
                <CallToActionButton
                  title="Book Call Back"
                  icon={<Calendar />}
                  onClick={() =>
                    handleBookCall(
                      "https://calendly.com/product-lead/finance-app"
                    )
                  }
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default HelpAndSupport;
